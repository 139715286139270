#inviteDropdown > div.ap-combobox-input-box.visible > div > div {
  width: auto !important;
}

#inviteDropdown > div.ap-combobox-input-box.visible > div > div > div {
  width: 100% !important;
}

#inviteDropdown > div.ap-combobox-input-box.visible > div > div > div > div {
  width: 100% !important;
  max-width: fit-content !important;
}

#inviteDropdown > div.ap-combobox-input-box.visible > span.separte-line {
  display: none !important;
}

#inviteDropdown > div.ap-combobox-input-box > span.ap-combobox-toggle-box > i {
  display: none !important;
}
#inviteDropdown > div.ap-combobox-input-box > span.separte-line {
  display: none !important;
}
#inviteDropdown
  > div.ap-combobox-input-box
  > span.ap-combobox-clear-icon.showDeleteIcon {
  right: 0.4rem !important;
}

.ap-option-item {
  height: auto !important;
  padding: 8px 12px !important;
}

body > div:nth-child(12) > div > div > div.ap-modal-footer > div {
  flex: 1 !important;
  justify-content: space-between !important;
}

#shareModal {
  padding-bottom: 100px !important;
}

.a-date-range-picker-double-calendar {
  right: 0 !important;
}

#shareModal > div > div.ap-modal-footer > div {
  flex: 1 !important;
  justify-content: space-between !important;
}
